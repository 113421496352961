import _ from 'lodash'
import { Connection, FeatureName } from '@wix/thunderbolt-symbols'
import { getDisplayedId, getFullId, isDisplayedOnly } from '@wix/thunderbolt-commons'
import { BootstrapData } from '../types'
import { RawModel } from './types'
import RenderedAPI from './renderedService'
import { MasterPageId } from './constants'

export const getAPIsOverModel = (model: RawModel, bootstrapData: BootstrapData) => {
	const getPageIdByCompId = (compId: string) => (model.rawMasterPageStructure[compId] ? MasterPageId : bootstrapData.currentPageId)
	const getCompIdByWixCodeNickname = (nickname: string) => _.get(getConnectionsByCompId('wixCode', nickname), [0, 'compId'])
	const getParentId = (compId: string) => _.findKey(getStructureModel(), ({ components }) => components && components.includes(compId))
	const getCompType = (compId: string): string => {
		const { componentType } = getStructureModelComp(compId)
		return componentType
	}
	const getControllerTypeByCompId = (compId: string) => {
		const appControllers = _.find(getApplications(), (controllers) => !!controllers[compId])
		return _.get(appControllers, [compId, 'controllerType'], '')
	}
	const getRepeaterIdByCompId = (compId: string) => model.platformModel.compIdToRepeaterId[compId]
	const renderedServiceApi = RenderedAPI({ model, getCompType, getParentId })
	const getRoleForCompId = (compId: string, controllerCompId: string) =>
		_.findKey(getControllerConnections(controllerCompId), (connections: Array<Connection>) => connections.some((connection: Connection) => connection.compId === compId))
	const isComponentInsideRepeater = (compId: string) => !!getRepeaterIdByCompId(compId)

	function getDisplayedIdsOfRepeaterTemplate(templateId: string) {
		const repeaterCompId = getRepeaterIdByCompId(templateId)
		if (!repeaterCompId) {
			return []
		}
		const repeaterItems = getCompProps(repeaterCompId).items
		return repeaterItems.map((itemId: string) => getDisplayedId(templateId, itemId))
	}

	function getOnLoadProperties(compId: string): { hiddenOnLoad: boolean; collapseOnLoad: boolean } {
		if (isDisplayedOnly(compId)) {
			return getOnLoadProperties(getFullId(compId))
		}
		const { hiddenOnLoad, collapseOnLoad } = model.platformModel.onLoadProperties[compId] || {}
		return {
			hiddenOnLoad: Boolean(hiddenOnLoad),
			collapseOnLoad: Boolean(collapseOnLoad)
		}
	}

	function getCompProps(compId: string) {
		return model.propsModel[compId]
	}

	function updateProps(compId: string, props: unknown) {
		if (!model.propsModel[compId]) {
			model.propsModel[compId] = {}
		}
		_.assign(model.propsModel[compId], props)
	}

	function getApplications() {
		return model.platformModel.applications
	}

	function getControllerConnections(controllerId: string) {
		return _.get(model.platformModel.connections, [controllerId], {})
	}

	function getConnectionsByCompId(controllerId: string, compId: string) {
		return _.get(model.platformModel.connections, [controllerId, compId], [])
	}

	function getStructureModel() {
		return model.structureModel
	}

	function getStructureModelComp(compId: string) {
		const fullCompId = isDisplayedOnly(compId) ? getFullId(compId) : compId
		return model.structureModel[fullCompId]
	}

	function getCompSdkData(compId: string) {
		if (isDisplayedOnly(compId)) {
			return model.platformModel.sdkData[compId] || model.platformModel.sdkData[getFullId(compId)]
		}
		return model.platformModel.sdkData[compId]
	}

	return {
		getAllConnections: () => model.platformModel.connections,
		getApplications,
		getCompIdByWixCodeNickname,
		getCompIdConnections: () => model.platformModel.compIdConnections,
		getCompProps,
		getCompSdkData,
		getCompType,
		getConnectionsByCompId,
		getContainerChildrenIds: (compId: string) => model.platformModel.containersChildrenIds[compId] || [],
		getControllerConnections,
		getControllerTypeByCompId,
		getControllers: () => model.platformModel.orderedControllers,
		getDisplayedIdsOfRepeaterTemplate,
		getFeaturePageConfig: (feature: FeatureName) => model.pageConfig[feature] || {},
		getOnLoadProperties,
		getPageIdByCompId,
		getParentId,
		getPropsModel: () => model.propsModel,
		getRepeaterIdByCompId,
		getRoleForCompId,
		getStaticEvents: () => model.platformModel.staticEvents,
		getStructureModel,
		getStructureModelComp,
		isComponentInsideRepeater,
		isController: (compId: string) => model.platformModel.orderedControllers.includes(compId),
		isRendered: (compId: string | undefined) => renderedServiceApi.isRendered(compId),
		updateProps
	}
}

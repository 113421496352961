import {
	namespace,
	DashboardWixCodeSdkFactoryInitialState,
	DashboardWixCodeSdkHandlers,
	DashboardWixCodeSdkWixCodeApi,
} from '..'
import { dashboardApiFacadeFactory } from './services/dashboardApiFacadeFactory'
import { callDashboardApiFactory } from './services/callDashboardApiFactory'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function DashboardSdkFactory(
	_: DashboardWixCodeSdkFactoryInitialState,
	{ getDashboardApi }: DashboardWixCodeSdkHandlers
): { [namespace]: DashboardWixCodeSdkWixCodeApi } {
	const callDashboardApi = callDashboardApiFactory(getDashboardApi)

	return {
		[namespace]: dashboardApiFacadeFactory(callDashboardApi),
	}
}
